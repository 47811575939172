<template>
  <a-modal
    title="视频下载"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @cancel="handleCancel"
    @ok="handleConfirm"
  >
    <p>是否下载视频？</p>
    <p>备注：视频下载后，使用要注意版权问题！</p>
  </a-modal>
</template>

<script>
export default {
  name: 'DownloadModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    confirmLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    //
    handleCancel () {
      this.$emit('cancel')
    },
    //
    handleConfirm () {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="less" scoped>

</style>
