<template>
  <div class="content">
    <search-form
        :search-form="searchForm"
        :material-source-list="materialSourceList"
        :video-tag-list="videoTagList"
        @material-source-change="handleMaterialSourceChange"
        @all-follow-change="handleAllFollowChange"
        @search="handleSearch"
        @reset="handleReset"
    />
    <a-row>
      <a-col :span="16">
        <a-form-model
            class="filter-form"
            ref="filterForm"
            :model="filterForm"
            layout="inline"
        >
          <a-form-model-item label="综合排序" prop="time_sort">
            <a-select
                v-model="filterForm.time_sort"
                style="width: 250px;"
                @change="handleSortChange"
            >
              <a-select-option
                  v-for="item in sortList"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="发布时间" prop="publish_time" v-if="tableType != 8">
            <a-select
                v-model="filterForm.publish_time"
                style="width: 250px;"
                @change="handleTimeChange"
            >
              <a-select-option
                  v-for="item in timeList"
                  :key="item.value"
                  :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-col>
      <a-col :span="8">
        <p class="tips">
          <span>更新时间：{{ updateInfo.end_time || "--" }}</span>
          <span>更新数据：{{ updateInfo.update_count || 0 }}条</span>
        </p>
      </a-col>
    </a-row>
    <data-table
        :loading="loading"
        :columns="columns"
        :data="videoList"
        :pagination="pagination"
        :type="tableType"
        :focusFlag="searchForm.focus_flag"
        @pagination-change="handlePaginationChange"
        @action="handleAction"
    />

    <!-- 下载提示弹框 -->
    <download-modal
        :visible="downloadVisible"
        :confirm-loading="confirmLoading"
        @cancel="handleDownloadCancel"
        @confirm="handleDownloadConfirm"
    />

    <!-- 关注/取消关注提示弹框 -->
    <follow-modal
        :visible="followVisible"
        :confirm-loading="confirmLoading"
        @cancel="handleFollowCancel"
        @confirm="handleFollowConfirm"
    />

    <!-- 屏蔽/取消屏蔽提示弹框 -->
    <shield-modal
        :visible="shieldVisible"
        :confirm-loading="confirmLoading"
        @cancel="handleShieldCancel"
        @confirm="handleShieldConfirm"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SearchForm from "./components/SearchForm";
import DataTable from "./components/DataTable";
import DownloadModal from "./components/DownloadModal";
import FollowModal from "../components/FollowModal";
import ShieldModal from "../components/ShieldModal";
import { columnsList, materialSourceList, sortList, timeList } from "./constant";
import creativeApi from "@/api/creativeCollection";
import moment from "moment";

export default {
  name: "materialCollection",
  components: {
    SearchForm,
    DataTable,
    DownloadModal,
    FollowModal,
    ShieldModal
  },
  data() {
    return {
      searchForm: {
        platform_type: 1,
        focus_flag: false,
        tags: "",
        aweme_title: "",
      },
      materialSourceList,
      videoTagList: [],
      filterForm: {
        time_sort: "0",
        publish_time: "d"
      },
      sortList,
      timeList,
      loading: false,
      columns: columnsList[0],
      videoList: [],
      pagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      updateInfo: {
        end_time: "",
        update_count: ""
      },
      tableType: 1,
      confirmLoading: false,
      downloadVisible: false,
      followVisible: false,
      shieldVisible: false
    };
  },
  async created() {
    try {
      const {code, data, message} = await this.$api.AppService.getUserInfo();

      if(code === 200) {
        this.$store.commit("SET_USER_INFO", data);
        this.fetchMaterialList();
        this.fetchMaterialUpdateCount();
      } else {
        this.$message.error(message);
      }
    } catch(e) {
      console.log(e);
    }
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    // 处理素材来源改变
    handleMaterialSourceChange(val) {
      this.columns = columnsList[val - 1];
      this.videoList = [];
      this.tableType = val;
      this.pagination.page_num = 1;
      if(val == 8) {
        this.filterForm.publish_time = "";
      }
      this.fetchMaterialList();
      this.fetchMaterialUpdateCount();
    },
    // 处理全部关注改变
    handleAllFollowChange() {
      this.pagination.page_num = 1;
      this.fetchMaterialList();
      this.fetchMaterialUpdateCount();
    },
    // 处理查询
    handleSearch() {
      this.pagination.page_num = 1;
      this.fetchMaterialList();
      this.fetchMaterialUpdateCount();
    },
    // 处理重置
    handleReset() {
      this.searchForm.aweme_title = "";
      this.pagination.page_num = 1;
      this.fetchMaterialList();
      this.fetchMaterialUpdateCount();
    },
    // 处理综合排序改变
    handleSortChange() {
      this.pagination.page_num = 1;
      this.fetchMaterialList();
      this.fetchMaterialUpdateCount();
    },
    // 处理发布时间改变
    handleTimeChange() {
      this.pagination.page_num = 1;
      this.fetchMaterialList();
      this.fetchMaterialUpdateCount();
    },
    // 处理分页改变
    handlePaginationChange(page_num, page_size) {
      this.pagination.page_num = page_num;
      this.pagination.page_size = page_size;
      this.fetchMaterialList();
      this.fetchMaterialUpdateCount();
    },
    // 获取素材列表
    async fetchMaterialList() {
      this.loading = true;
      try {
        const {focus_flag, ...restSearchForm} = this.searchForm;
        const {page_num, page_size} = this.pagination;
        const params = {
          current_userid: this.userInfo.id,
          ...restSearchForm,
          focus_flag: focus_flag ? "1" : "0",
          ...this.filterForm,
          page_num,
          page_size
        };
        const res = await creativeApi.fetchMaterialList(params);
        if(res.code === 200) {
          console.log(res.data.list);
          this.videoList = res.data.list.map((item, index) => {
            return {...item, key: index};
          });
          this.pagination.total = res.data.total;
          this.loading = false;
        } else {
          this.$message.error(res.msg || res.errmsg);
          this.loading = false;
        }
      } catch(e) {
        console.log(e);
        this.loading = false;
      }
    },
    // 获取素材更新数
    async fetchMaterialUpdateCount() {
      try {
        const {platform_type} = this.searchForm;
        const {publish_time} = this.filterForm;
        const params = {
          platform_type,
          query_time: publish_time,
          start_time: this.searchForm.start_time,
          end_time: this.searchForm.end_time
        };
        const res = await creativeApi.fetchMaterialUpdateCount(params);

        this.updateInfo = res;
      } catch(e) {
        console.log(e);
      }
    },
    //
    handleAction(type, record) {
      this.selectedRecord = record;

      // 下载
      if(type === "download") {
        if(
            (this.searchForm.platform_type !== 7 && !this.selectedRecord.aweme_url) ||
            (this.searchForm.platform_type === 7 && !this.selectedRecord.oss_name)
        ) {
          this.$message.error("该视频地址不存在");
          return false;
        }

        this.downloadVisible = true;
      }

      // 关注
      if(type === "follow") {
        this.state = 1;
        this.followVisible = true;
      }

      // 屏蔽
      if(type === "shield") {
        this.state = 2;
        this.shieldVisible = true;
      }
    },
    //
    async handleDownloadConfirm() {
      this.downloadVisible = false;

      if(this.searchForm.platform_type === 7) {
        window.open(process.env.VUE_APP_OSS_HOST2 + `/${ this.selectedRecord.oss_name }`);
      } else {
        window.open(this.selectedRecord.aweme_url);
      }
    },
    //
    handleDownloadCancel() {
      this.downloadVisible = false;
    },
    //
    async handleFollowConfirm() {
      this.confirmLoading = true;
      try {
        const params = {
          author_name: this.selectedRecord.nickname,
          platform: this.searchForm.platform_type
        };
        const res = await creativeApi.fetchFollowAccountState(params);

        if(res.code === 200) {
          if(res.data.state === -1) {
            this.addFollowAccount();
          } else {
            // this.$message.error(res.data.message)
            // this.confirmLoading = false

            if(this.state === res.data.state) {
              this.$message.error("该帐号已关注过");
              this.confirmLoading = false;
            } else {
              this.updateFollowAccount(res.data.record);
            }
          }
        } else {
          this.$message.error(res.msg || res.errmsg);
          this.confirmLoading = false;
        }
      } catch(e) {
        console.log(e);
        this.confirmLoading = false;
      }
    },
    //
    handleFollowCancel() {
      this.followVisible = false;
    },
    //
    async handleShieldConfirm() {
      this.confirmLoading = true;
      try {
        const params = {
          author_name: this.selectedRecord.nickname,
          platform: this.searchForm.platform_type
        };
        const res = await creativeApi.fetchFollowAccountState(params);

        if(res.code === 200) {
          if(res.data.state === -1) {
            this.addFollowAccount();
          } else {
            // this.$message.error(res.data.message)
            // this.confirmLoading = false

            if(this.state === res.data.state) {
              this.$message.error("该帐号已屏蔽过");
              this.confirmLoading = false;
            } else {
              this.updateFollowAccount(res.data.record);
            }
          }
        } else {
          this.$message.error(res.msg || res.errmsg);
          this.confirmLoading = false;
        }
      } catch(e) {
        console.log(e);
        this.confirmLoading = false;
      }
    },
    //
    handleShieldCancel() {
      this.shieldVisible = false;
    },
    // 添加关注帐号
    async addFollowAccount() {
      this.confirmLoading = true;
      try {
        const {platform_type} = this.searchForm;
        const {author_id, nickname, avatar} = this.selectedRecord;
        const params = {
          state: this.state,
          platform: platform_type,
          author_id: author_id,
          author_name: nickname,
          author_url: avatar
        };
        const res = await creativeApi.addFollowAccount(params);

        if(res.code === 200) {
          if(this.state === 1) {
            this.$message.success("关注帐号成功");
            this.confirmLoading = false;
            this.followVisible = false;
          } else {
            this.$message.success("屏蔽帐号成功");
            this.confirmLoading = false;
            this.shieldVisible = false;
            this.fetchMaterialList();
            this.fetchMaterialUpdateCount();
          }
        } else {
          this.$message.error(res.msg || res.errmsg);
          this.confirmLoading = false;
        }
      } catch(e) {
        console.log(e);
        this.confirmLoading = false;
      }
    },
    // 更新关注帐号
    async updateFollowAccount(record) {
      this.confirmLoading = true;
      try {
        const params = {
          id: record.id,
          state: this.state
        };
        const res = await creativeApi.updateFollowAccount(params);

        if(res.code === 200) {
          if(this.state === 1) {
            this.$message.success("关注帐号成功");
            this.confirmLoading = false;
            this.followVisible = false;
          } else {
            this.$message.success("屏蔽帐号成功");
            this.confirmLoading = false;
            this.shieldVisible = false;
            this.fetchMaterialList();
            this.fetchMaterialUpdateCount();
          }
        } else {
          this.$message.error(res.msg || res.errmsg);
          this.confirmLoading = false;
        }
      } catch(e) {
        console.log(e);
        this.confirmLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tips {
  line-height: 40px;
  text-align: right;

  span {
    margin: 0 0 0 20px;
  }
}
</style>
