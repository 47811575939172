<template>
  <div class="data-table" ref="dataTable">
    <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :scroll="scroll"
        :pagination="false"
    >
      <div class="video-info" slot="carInfo" slot-scope="text, record">
        <div class="video-info__cover">
          <base-cover
              :src="record.aweme_cover || ''"
          />
          <div class="duration">
            <span>{{ record.duration | formatNumber }}秒</span>
          </div>
        </div>
        <div class="video-info__content">
          <p>
            <span class="label">视频名称：</span>
            <span class="value">{{ record.aweme_title || "--" }}</span>
          </p>
          <p>
            <span class="label">发布日期：</span>
            <span class="value">{{ record.publish_time | formatPublishTime }}</span>
          </p>
        </div>
      </div>
      <div class="video-info" slot="videoInfo" slot-scope="text, record">
        <div class="video-info__cover">
          <base-cover
              :src="record.aweme_cover || ''"
          />
          <div class="duration">
            <span>{{ record.duration | formatNumber }}秒</span>
          </div>
        </div>
        <div class="video-info__content">
          <p>
            <span class="value">{{ record.aweme_title }}</span>
          </p>
          <p v-if="!(type === 7)">
            <span class="label">帐号名称：</span>
            <span class="value">{{ record.nickname || "--" }}</span>
          </p>
          <p v-if="!(type === 6 || type === 7)">
            <span class="label">发布日期：</span>
            <span class="value">{{ record.publish_time | formatPublishTime }}</span>
          </p>
        </div>
      </div>
      <div slot="play_count" slot-scope="text, record">
        {{ record.play_count | formatNumber }}
      </div>
      <div slot="digg_count" slot-scope="text, record">
        {{ record.digg_count | formatNumber }}
      </div>
      <div slot="comment_count" slot-scope="text, record">
        {{ record.comment_count | formatNumber }}
      </div>
      <div slot="collect_count" slot-scope="text, record">
        {{ record.collect_count | formatNumber }}
      </div>
      <div slot="share_count" slot-scope="text, record">
        {{ record.share_count | formatNumber }}
      </div>
      <div slot="download_count" slot-scope="text, record">
        {{ record.download_count | formatNumber }}
      </div>
      <div slot="duration" slot-scope="text, record">
        {{ record.duration | formatNumber }}秒
      </div>
      <div slot="action" slot-scope="text, record">
        <a-space>
          <a-button
              type="link"
              @click="handleAction('download', record)"
          >
            下载
          </a-button>
          <template v-if="!focusFlag">
            <a-button
                v-if="type !== 7 && type !== 8"
                type="link"
                @click="handleAction('follow', record)"
            >
              关注
            </a-button>
          </template>
          <a-button
              v-if="type !== 7  && type !== 8"
              type="link"
              @click="handleAction('shield', record)"
          >
            屏蔽
          </a-button>
        </a-space>
      </div>
    </a-table>
    <base-pagination
        :currentPage="pagination.page_num"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
import moment from "moment";
import BaseCover from "@/components/BaseCover";

export default {
  name: "DataTable",
  components: {
    BaseCover
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    pagination: {
      type: Object,
      default() {
        return {
          page_num: 1,
          page_size: 10,
          total: 0
        };
      }
    },
    type: {
      type: Number,
      default: 1
    },
    focusFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scroll: {},
      $_sidebarElm: null,
      $_resizeHandler: null
    };
  },
  mounted() {
    this.setScroll();
    this.$_resizeHandler = () => {
      this.setScroll();
    };
    this.$_initResizeEvent();
    this.$_initSidebarResizeEvent();
  },
  beforeDestroy() {
    this.$_destroyResizeEvent();
    this.$_destroySidebarResizeEvent();
  },
  activated() {
    this.$_initResizeEvent();
    this.$_initSidebarResizeEvent();
  },
  deactivated() {
    this.$_destroyResizeEvent();
    this.$_destroySidebarResizeEvent();
  },
  watch: {
    columns(newVal, oldVal) {
      this.setScroll();
    }
  },
  methods: {
    //
    handlePaginationChange(currentPage, pageSize) {
      this.$emit("pagination-change", currentPage, pageSize);
    },
    //
    handleAction(type, record) {
      this.$emit("action", type, record);
    },
    setScroll() {
      const width = this.$refs.dataTable.offsetWidth;
      const columnsWidth = this.columns.reduce((total, currVal) => {
        return total + currVal.width;
      }, 0);

      if(width < columnsWidth) {
        this.scroll = {x: columnsWidth, y: "calc(100vh - 180px)"};
      } else {
        this.scroll = {};
      }
    },
    $_initResizeEvent() {
      window.addEventListener("resize", this.$_resizeHandler);
    },
    $_destroyResizeEvent() {
      window.removeEventListener("resize", this.$_resizeHandler);
    },
    $_sidebarResizeHandler(e) {
      if(e.propertyName === "width") {
        this.$_resizeHandler();
      }
    },
    $_initSidebarResizeEvent() {
      this.$_sidebarElm = document.getElementsByClassName("ant-layout-sider")[0];
      this.$_sidebarElm && this.$_sidebarElm.addEventListener("transitionend", this.$_sidebarResizeHandler);
    },
    $_destroySidebarResizeEvent() {
      this.$_sidebarElm && this.$_sidebarElm.removeEventListener("transitionend", this.$_sidebarResizeHandler);
    },
  },
  filters: {
    formatPublishTime(val) {
      if(!val) return "--";

      return moment(val).format("YYYY-MM-DD");
    },
    formatNumber(val) {
      if(!val) return 0;

      return val;
    }
  }
};
</script>

<style lang="less" scoped>
.video-info {
  display: flex;
  width: 100%;

  &__cover {
    position: relative;
    width: 124px;
    height: 165px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    flex: 1;
    padding: 0 10px;
  }

  .duration {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 5px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);

    span {
      color: #FFF;
      font-size: 14px;
      line-height: 14px;
    }
  }

  p {
    display: flex;

    .value {
      flex: 1;
      text-align: left;
    }
  }
}

.ant-btn-link {
  padding: 0;
}
</style>
