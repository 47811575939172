<template>
  <a-form-model
      class="search-form"
      ref="searchForm"
      :model="searchForm"
      layout="inline"
  >
    <a-row>
      <a-col :span="24">
        <a-form-model-item label="素材来源" prop="platform_type">
          <a-select
              v-model="searchForm.platform_type"
              style="width: 250px;"
              @change="handleMaterialSourceChange"
          >
            <a-select-option
                v-for="item in materialSourceList"
                :key="item.value"
                :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="focus_flag" v-if="searchForm.platform_type != 8">
          <a-checkbox
              v-model="searchForm.focus_flag"
              @change="handleAllFollowChange"
          >
            全部关注
          </a-checkbox>
        </a-form-model-item>
      </a-col>
      <!-- <a-col :span="24">
        <a-form-model-item class="tags-form-item" label="筛选标签" prop="tags">
          <a-radio-group v-model="searchForm.tags">
            <a-radio-button
              v-for="item in videoTagList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
      </a-col> -->
      <a-col :span="24">
        <a-form-item v-if="searchForm.platform_type == 8" label="品牌">
          <a-select
              v-model="searchForm.brand"
              :not-found-content="fetching ? undefined : null"
              allow-clear
              show-search
              option-filter-prop="children"
              @change="onChangeBrand"
              placeholder="请选择品牌"
              style="width: 250px"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" tip="加载中..."></a-spin>
            <a-select-option key="" value="">
              不限
            </a-select-option>
            <a-select-option v-for="item in brand_list" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="searchForm.platform_type == 8" label="车系">
          <a-select
              v-model="searchForm.series"
              :not-found-content="fetching ? undefined : null"
              allow-clear
              show-search
              option-filter-prop="children"
              placeholder="请选择车系"
              style="width: 250px"
          >
            <a-spin v-if="fetching" slot="notFoundContent" size="small" tip="加载中..."></a-spin>
            <a-select-option key="" value="">
              不限
            </a-select-option>
            <a-select-option v-for="item in series_list" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-model-item label="标题检索" prop="aweme_title">
          <a-input
              v-model="searchForm.aweme_title"
              placeholder="请输入"
              allow-clear
              style="width: 250px;"
          />
        </a-form-model-item>
        <a-form-model-item v-if="searchForm.platform_type == 8" label="发布时间">
          <a-range-picker allowClear
                          style="width: 390px"
                          format="YYYY-MM-DD"
                          :placeholder="['开始时间', '结束时间']" v-model="searchForm.time"
                          inputReadOnly
                          @change="onChangeDate"/>
        </a-form-model-item>
        <a-form-model-item>
          <a-space class="space">
            <a-button type="primary" @click="handleSearch">
              查询
            </a-button>
            <a-button @click="handleReset">
              重置
            </a-button>
          </a-space>
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import creativeApi from "@/api/creativeCollection";
import moment from "moment";

export default {
  name: "SearchForm",
  props: {
    searchForm: {
      type: Object,
      default() {
        return {
          a: 1,
          b: false,
          c: 1,
          d: ""
        };
      }
    },
    materialSourceList: {
      type: Array,
      default() {
        return [];
      }
    },
    videoTagList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      fetching: false,
      is_brand: false,
      brand_list: [],
      series_list: []
    };
  },
  mounted() {
    this.getBrandList();
  },
  methods: {
    moment,
    //日期选择框
    onChangeDate(dates, dateStrings) {
      console.log(dates, dateStrings);
      if(dateStrings) {
        this.$set(this.searchForm, "time", [dateStrings[0], dateStrings[1]]);
        this.$set(this.searchForm, "start_time", dateStrings[0] + " 00:00:00");
        this.$set(this.searchForm, "end_time", dateStrings[1] + " 23:59:59");
      } else {
        this.searchForm.end_time = "";
        this.searchForm.start_time = "";
      }
    },
    onChangeBrand(val) {
      this.$set(this.searchForm, "series", undefined);
      if(val) {
        this.getSeriesList(val);
      } else {
        this.is_brand = true;
        this.series_list = [];
      }
    },
    //获取品牌
    getBrandList() {
      creativeApi.getBrandsList().then((res) => {
        this.brand_list = res.data || [];
      });
    },
    getSeriesList(brand) {
      creativeApi.getSeries(brand).then((res) => {
        this.is_brand = false;

        if(res.code === 200) {
          this.series_list = res.data || [];
        } else {
          console.error(res);
        }
      });
    },
    // 处理素材来源改变
    handleMaterialSourceChange() {
      if(this.searchForm.platform_type == 8) {
        this.$set(this.searchForm, "time", [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]);
        this.$set(this.searchForm, "start_time", moment().format("YYYY-MM-DD") + " 00:00:00");
        this.$set(this.searchForm, "end_time", moment().format("YYYY-MM-DD") + " 23:59:59");
      }
      this.$emit("material-source-change", this.searchForm.platform_type);
    },
    // 处理全部关注改变
    handleAllFollowChange() {
      this.$emit("all-follow-change");
    },
    // 处理查询
    handleSearch() {
      this.$emit("search");
    },
    // 处理重置
    handleReset() {
      if(this.searchForm.platform_type == 8) {
        this.$set(this.searchForm, "time", [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]);
        this.$set(this.searchForm, "start_time", moment().format("YYYY-MM-DD") + " 00:00:00");
        this.$set(this.searchForm, "end_time", moment().format("YYYY-MM-DD") + " 23:59:59");
      } else {
        this.searchForm.time = [];
        this.searchForm.end_time = "";
        this.searchForm.start_time = "";
      }
      this.searchForm.brand = undefined;
      this.searchForm.series = undefined;

      // this.$refs.searchForm.resetFields()
      this.$emit("reset");
    }
  }
};
</script>

<style lang="less" scoped>
.search-form {
  &.ant-form-inline {
    .ant-form-item {
      margin-bottom: 10px;

      /deep/ .ant-form-item-label {
        width: 70px;
      }
    }

    .tags-form-item {
      display: block;
      margin-right: 0;
      overflow: hidden;

      /deep/ .ant-form-item-label {
        float: left;
        width: 70px;
      }

      /deep/ .ant-form-item-control-wrapper {
        display: block;
        margin-left: 70px;
      }
    }
  }

  .ant-radio-button-wrapper {
    margin: 5px 10px 5px 0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    &::before {
      display: none;
    }

    &:hover {
      border-color: #40a9ff;
    }
  }
}
</style>
