// 素材来源列表
export const materialSourceList = [
  {label: "抖音", value: 1},
  {label: "快手", value: 2},
  {label: "懂车帝", value: 3},
  {label: "新片场", value: 4},
  {label: "潮点视频", value: 5},
  {label: "视觉中国", value: 6},
  {label: "爱给网", value: 7},
  {label: "汽车之家", value: 8},
];

// 综合排序列表
export const sortList = [
  {label: "发布时间倒序", value: "0"},
  {label: "发布时间正序", value: "1"}
];

// 发布时间列表
export const timeList = [
  {label: "一天内", value: "d"},
  {label: "一周内", value: "w"},
  {label: "半年内", value: "y"}
];

// 抖音
export const columns0 = [
  {
    title: "视频信息",
    key: "videoInfo",
    width: 400,
    align: "center",
    scopedSlots: {customRender: "videoInfo"}
  },
  {
    title: "播放次数",
    dataIndex: "play_count",
    key: "play_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.play_count - b.play_count,
    scopedSlots: {customRender: "play_count"}
  },
  {
    title: "点赞数",
    dataIndex: "digg_count",
    key: "digg_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.digg_count - b.digg_count,
    scopedSlots: {customRender: "digg_count"}
  },
  {
    title: "评论数",
    dataIndex: "comment_count",
    key: "comment_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.comment_count - b.comment_count,
    scopedSlots: {customRender: "comment_count"}
  },
  {
    title: "收藏数",
    dataIndex: "collect_count",
    key: "collect_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.collect_count - b.collect_count,
    scopedSlots: {customRender: "collect_count"}
  },
  {
    title: "操作",
    key: "action",
    width: 180,
    align: "center",
    fixed: "right",
    scopedSlots: {customRender: "action"}
  }
];

// 快手
export const columns1 = [
  {
    title: "视频信息",
    key: "videoInfo",
    width: 400,
    align: "center",
    scopedSlots: {customRender: "videoInfo"}
  },
  {
    title: "播放次数",
    dataIndex: "play_count",
    key: "play_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.play_count - b.play_count,
    scopedSlots: {customRender: "play_count"}
  },
  {
    title: "点赞数",
    dataIndex: "digg_count",
    key: "digg_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.digg_count - b.digg_count,
    scopedSlots: {customRender: "digg_count"}
  },
  {
    title: "评论数",
    dataIndex: "comment_count",
    key: "comment_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.comment_count - b.comment_count,
    scopedSlots: {customRender: "comment_count"}
  },
  {
    title: "操作",
    key: "action",
    width: 180,
    align: "center",
    fixed: "right",
    scopedSlots: {customRender: "action"}
  }
];

// 懂车帝
export const columns2 = [
  {
    title: "视频信息",
    key: "videoInfo",
    width: 400,
    align: "center",
    scopedSlots: {customRender: "videoInfo"}
  },
  {
    title: "播放次数",
    dataIndex: "play_count",
    key: "play_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.play_count - b.play_count,
    scopedSlots: {customRender: "play_count"}
  },
  {
    title: "赞同数",
    dataIndex: "digg_count",
    key: "digg_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.digg_count - b.digg_count,
    scopedSlots: {customRender: "digg_count"}
  },
  {
    title: "评论数",
    dataIndex: "comment_count",
    key: "comment_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.comment_count - b.comment_count,
    scopedSlots: {customRender: "comment_count"}
  },
  {
    title: "操作",
    key: "action",
    width: 180,
    align: "center",
    fixed: "right",
    scopedSlots: {customRender: "action"}
  }
];

// 新片场
export const columns3 = [
  {
    title: "视频信息",
    key: "videoInfo",
    width: 400,
    align: "center",
    scopedSlots: {customRender: "videoInfo"}
  },
  {
    title: "播放次数",
    dataIndex: "play_count",
    key: "play_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.play_count - b.play_count,
    scopedSlots: {customRender: "play_count"}
  },
  {
    title: "点赞数",
    dataIndex: "digg_count",
    key: "digg_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.digg_count - b.digg_count,
    scopedSlots: {customRender: "digg_count"}
  },
  {
    title: "收藏数",
    dataIndex: "collect_count",
    key: "collect_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.collect_count - b.collect_count,
    scopedSlots: {customRender: "collect_count"}
  },
  {
    title: "分享数",
    dataIndex: "share_count",
    key: "share_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.share_count - b.share_count,
    scopedSlots: {customRender: "share_count"}
  },
  {
    title: "评论数",
    dataIndex: "comment_count",
    key: "comment_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.comment_count - b.comment_count,
    scopedSlots: {customRender: "comment_count"}
  },
  {
    title: "操作",
    key: "action",
    width: 180,
    align: "center",
    fixed: "right",
    scopedSlots: {customRender: "action"}
  }
];

// 潮点视频
export const columns4 = [
  {
    title: "视频信息",
    key: "videoInfo",
    align: "center",
    width: 400,
    scopedSlots: {customRender: "videoInfo"}
  },
  {
    title: "点击次数",
    dataIndex: "play_count",
    key: "play_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.play_count - b.play_count,
    scopedSlots: {customRender: "play_count"}
  },
  {
    title: "操作",
    key: "action",
    width: 180,
    align: "center",
    fixed: "right",
    scopedSlots: {customRender: "action"}
  }
];

// 视觉中国
export const columns5 = [
  {
    title: "视频信息",
    key: "videoInfo",
    width: 400,
    align: "center",
    scopedSlots: {customRender: "videoInfo"}
  },
  {
    title: "操作",
    key: "action",
    width: 180,
    align: "center",
    scopedSlots: {customRender: "action"}
  }
];

// 爱给网
export const columns6 = [
  {
    title: "视频信息",
    key: "videoInfo",
    width: 400,
    align: "center",
    scopedSlots: {customRender: "videoInfo"}
  },
  {
    title: "下载量",
    dataIndex: "download_count",
    key: "download_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.download_count - b.download_count,
    scopedSlots: {customRender: "download_count"}
  },
  {
    title: "视频时长",
    dataIndex: "duration",
    key: "duration",
    width: 180,
    align: "center",
    sorter: (a, b) => a.duration - b.duration,
    scopedSlots: {customRender: "duration"}
  },
  {
    title: "操作",
    key: "action",
    width: 180,
    align: "center",
    fixed: "right",
    scopedSlots: {customRender: "action"}
  }
];

// 汽车之家
export const columns7 = [
  {
    title: "视频信息",
    key: "carInfo",
    width: 400,
    align: "center",
    scopedSlots: {customRender: "carInfo"}
  },
  {
    title: "播放次数",
    dataIndex: "play_count_home",
    key: "play_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.play_count - b.play_count,
    // scopedSlots: { customRender: 'play_count_home' }
  },
  {
    title: "评论数",
    dataIndex: "comment_count",
    key: "comment_count",
    width: 180,
    align: "center",
    sorter: (a, b) => a.comment_count - b.comment_count,
    scopedSlots: {customRender: "comment_count"}
  },
  {
    title: "操作",
    key: "action",
    width: 180,
    align: "center",
    fixed: "right",
    scopedSlots: {customRender: "action"}
  }
];

export const columnsList = [
  columns0,
  columns1,
  columns2,
  columns3,
  columns4,
  columns5,
  columns6,
  columns7
];
